import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StaggeredSvg from '../components/StaggeredSvg/index'
import NotFoundSvg from '../images/notfound.svg'
import styled from 'styled-components'
import ContentContainer from '../components/ContentContainer'
import { Link } from 'gatsby'

function NotFoundPage () {
	return (
		<Layout>
			<SEO title="404: Not found" />

			<SvgContainer>
				<StaggeredSvg duration={2}>
					<NotFoundSvg />
				</StaggeredSvg>
			</SvgContainer>

			<ContentContainer>
				<h1>Ah, shit.</h1>
				<p>So this page either doesn't exist or a link's been broken. Either way, I've been notified.</p>
				<p>As an apology, I'll let you in on a secret: you can click on the header images.</p>
				<p>If you're the forgiving type, you could also head back to the <Link to="/">home page</Link> and start over.</p>
			</ContentContainer>
		</Layout>
	)
}

const SvgContainer = styled.div`
	width: 80%;
	margin: 5rem auto;
`

export default NotFoundPage
